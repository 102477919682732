import {
  getIntervalString,
  getIntervals,
  getLastComplianceRelativeTime,
  getLastComplianceTime,
  getToleranceValue,
  getRemainingValue,
  getTolerances,
  getIntervalAndToleranceStringArray,
} from '@wingwork/common/src/maintenanceItem'
import { get } from 'lodash'
import type { MaintenanceNextDueStatus } from 'types/graphql'

export interface CurrentStatus {
  status?: MaintenanceNextDueStatus
  remainingValue?: number | string
  months?: number
  days?: number
}

export interface DueStatus {
  toleranceValue: string
  intervalAndToleranceStringArray: [string, string?][]
  remainingValue: CurrentStatus
  lastComplianceRelativeTime: string
  lastComplianceTime: string
  intervalString: string
  intervals: string[]
}

const useDueStatus = (mtxItem, aircraft): DueStatus => {
  if (!mtxItem || !aircraft) {
    return {
      toleranceValue: '',
      intervalAndToleranceStringArray: [],
      remainingValue: {} as CurrentStatus,
      lastComplianceRelativeTime: '',
      lastComplianceTime: '',
      intervalString: '',
      intervals: [],
    }
  }

  const latestAircraftUsageLog = get(aircraft, ['AircraftUsageLog', 0])

  const toleranceValue = getToleranceValue(mtxItem)
  const remainingValue = getRemainingValue(mtxItem, latestAircraftUsageLog)
  const lastComplianceRelativeTime = getLastComplianceRelativeTime(
    mtxItem,
    latestAircraftUsageLog
  )
  const lastComplianceTime = getLastComplianceTime(mtxItem)
  const intervalString =
    getIntervalString(mtxItem).length > 0
      ? `Interval: ${getIntervalString(mtxItem)}`
      : 'This is one-off task'
  const intervals = getIntervals(mtxItem)
  const tolerances = getTolerances(mtxItem)
  const intervalAndToleranceStringArray = getIntervalAndToleranceStringArray(
    mtxItem.cadenceValue
  )
  return {
    toleranceValue,
    tolerances,
    intervalAndToleranceStringArray,
    remainingValue,
    lastComplianceRelativeTime,
    lastComplianceTime,
    intervalString,
    intervals,
  }
}

export default useDueStatus
